import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import {
  UpdateUserInput, UpdateUserMutation, UpdateUserMutationVariables, User,
} from '@ew-math/graphql-generated-classes';
import { map } from 'rxjs/operators';
import { GraphQlService } from './graph-ql.service';
import { userFragment } from './common-auth.service';

@Injectable()
export class CommonUserService extends GraphQlService {

  update(data: UpdateUserInput, id?: User['id']) {
    return this.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
      mutation: gql`
        mutation UpdateUser($data: UpdateUserInput!, $id: ID) {
          updateUser(data: $data, id: $id) {
            ...UserFragment
          }
        }

        ${userFragment}
      `,
      variables: {data, id}
    }).pipe(map(data => data.updateUser))
  }

}

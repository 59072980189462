import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbAuthOAuth2JWTToken, NbPasswordAuthStrategy } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  LayoutService,
  StateService,
} from './utils';
import { passwordMinLength } from '../../../../../libs/api-interfaces/src/lib/constants';

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}


export const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        baseEndpoint: '/api',
        login: {
          endpoint: '/auth/login',
        },
        register: {
          redirect: {
            success: '/auth/login'
          },
          endpoint: '/auth/register',
          requireValidToken: false,
        },
        logout: {
          endpoint: '/auth/logout',
        },
        requestPass: {
          endpoint: '/auth/request-pass',
        },
        resetPass: {
          endpoint: '/auth/reset-pass',
          resetPasswordTokenKey: 'code',
        },
        refreshToken: {
          endpoint: '/auth/refresh-token',
        },
        token: {
          class: NbAuthOAuth2JWTToken,
          key: 'token',
        },
        validation: {
          password: {
            minLength: passwordMinLength,
          },
        }
      }),
    ],
    forms: {
      register: {
        terms: false,
        redirectDelay: 1000,
      },
      login: {
        redirectDelay: 0,
      },
      requestPassword: {
        redirectDelay: 2000,
      },
      resetPassword: {
        redirectDelay: 2000,
      },
      logout: {
        redirectDelay: 0,
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  LayoutService,
  StateService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}

<mat-dialog-content>
  <div [innerHTML]="text"></div>
</mat-dialog-content>
<mat-dialog-actions [class.one-button]="data.viewButtons?.length === 1">
  <button *ngIf="data.viewButtons && data.viewButtons.indexOf(buttons.cancel) !== -1" mat-button color="accent"
          (click)="dialogRef.close(buttons.cancel)" i18n>
    Cancel
  </button>
  <button *ngIf="data.viewButtons && data.viewButtons.indexOf(buttons.no) !== -1" mat-button color="accent"
          (click)="dialogRef.close(buttons.no)" i18n>
    No
  </button>
  <button *ngIf="data.viewButtons && data.viewButtons.indexOf(buttons.yes) !== -1" mat-button color="primary"
          (click)="dialogRef.close(buttons.yes)" i18n>
    Yes
  </button>
  <button *ngIf="data.viewButtons && data.viewButtons.indexOf(buttons.ok) !== -1" mat-button color="accent"
          (click)="dialogRef.close(buttons.ok)" i18n>
    Ok
  </button>
</mat-dialog-actions>

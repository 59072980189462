import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router } from '@angular/router';
import { NbAuthOAuth2JWTToken, NbAuthService } from '@nebular/auth';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IJwtPayload } from '@ew-math/api-interfaces';
import { UserType } from '@ew-math/graphql-generated-classes';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: NbAuthService, private router: Router) {
  }

  canActivate() {
    return this.authService.isAuthenticatedOrRefresh()
      .pipe(
        switchMap(authenticated => {
          if (!authenticated) {
            return of(this.router.parseUrl('/auth/login'));
          } else {
            return this.authService.getToken().pipe(
              map((token: NbAuthOAuth2JWTToken) => {
                const payload: IJwtPayload = token.isValid() ? token.getAccessTokenPayload() : null;
                if (payload && payload.id) {
                  if (payload.type === UserType.Admin) {
                    return true;
                  } else {
                    return this.router.parseUrl('/auth/logout');
                  }
                } else {
                  return this.router.parseUrl('/auth/login');
                }
              })
            );
          }
        }),
      );
  }
}

@Injectable()
export class NotAuthGuard implements CanActivateChild {

  constructor(private authService: NbAuthService, private router: Router) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot) {
    return this.authService.isAuthenticatedOrRefresh()
      .pipe(
        map(authenticated => {
          if (authenticated && childRoute.url[0].path !== 'logout') {
            return this.router.parseUrl('/');
          } else {
            return true;
          }
        }),
      );
  }
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbButtonModule, NbCardModule, NbIconModule, NbProgressBarModule, NbSearchModule } from '@nebular/theme';
import { AdminMaterialModule } from './admin-material.module';
import { LoaderComponent } from './components/loader/loader.component';
import { BeforeUnloadMessageDirective } from './directives/beforeUnloadMessage.directive';
import { AddBtnComponent } from './components/add-btn/add-btn.component';
import { MatSelectModule } from '@angular/material/select';
import { ItemsListComponent } from './components/items-list/items-list.component';
import { EditItemComponent } from './components/edit-item/edit-item.component';
import { CommonDialogComponent } from './components/dialog/common-dialog.component';


const MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  AdminMaterialModule,
  NbCardModule,
  NbProgressBarModule,
  MatSelectModule,
  NbSearchModule,
  NbIconModule,
  NbButtonModule,

];

const PIPES = [];

const COMPONENTS = [
  LoaderComponent,
  BeforeUnloadMessageDirective,
  AddBtnComponent,
  ItemsListComponent,
  EditItemComponent,
  CommonDialogComponent
];

const PROVIDERS = [];

@NgModule({
  imports: [
    ...MODULES
  ],
  declarations: [
    ...PIPES,
    ...COMPONENTS
  ],
  exports: [
    ...MODULES,
    ...PIPES,
    ...COMPONENTS
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ...PROVIDERS
      ]
    };
  }
}

<nb-card *ngIf="!waitingForItems; else loader" id="main-card">
  <nb-card-header>
    {{title}}
    <div *ngIf="searchPhrase" class="search-phrase">
      {{searchBy}}: {{searchPhrase}}
      <a routerLink="./" queryParamsHandling="merge" [queryParams]="{search: null}"><nb-icon icon="close-outline"></nb-icon></a>
    </div>
    <nb-search type="rotate-layout" *ngIf="withSearch" [tag]="searchPhrase" [placeholder]="searchBy"></nb-search>
  </nb-card-header>
  <nb-card-body>
    <mat-table [dataSource]="dataSource" *ngIf="dataSource?.data.length > 0" cdkDropList
               [cdkDropListData]="dataSource" [cdkDropListDisabled]="!reorderableList || dataSource?.data.length <= 1" (cdkDropListDropped)="onListDrop($event);table.renderRows()" >
      <ng-container matColumnDef="order" sticky>
        <mat-header-cell *matHeaderCellDef class="noflex"> Order</mat-header-cell>
        <mat-cell *matCellDef="let i = index" class="noflex">
          {{i + 1}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef> Email</mat-header-cell>
        <mat-cell *matCellDef="let item" [innerHTML]="item.email"></mat-cell>
      </ng-container>
      <ng-container matColumnDef="app_type">
        <mat-header-cell *matHeaderCellDef> App Type</mat-header-cell>
        <mat-cell *matCellDef="let item" [innerHTML]="item.app_type"></mat-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
        <mat-cell *matCellDef="let item" [innerHTML]="item.name"></mat-cell>
      </ng-container>
      <ng-container matColumnDef="created">
        <mat-header-cell *matHeaderCellDef> Created date</mat-header-cell>
        <mat-cell *matCellDef="let item">
          {{item.createdAt |  date:'dd.MM.yyyy HH:mm'}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="updated">
        <mat-header-cell *matHeaderCellDef> Last updated</mat-header-cell>
        <mat-cell *matCellDef="let item">
          {{item.updatedAt |  date:'dd.MM.yyyy HH:mm'}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef> Active</mat-header-cell>
        <mat-cell *matCellDef="let item">
          {{item.active ? 'Yes' : 'No'}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
        <mat-cell *matCellDef="let item">
          {{item.type}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="noflex"> Actions</mat-header-cell>
        <mat-cell *matCellDef="let item" class="noflex">
          <a routerLink="./{{item.id}}">
            <mat-icon>edit</mat-icon>
          </a>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnsToDisplay" cdkDrag [cdkDragData]="row"></mat-row>
    </mat-table>
    <mat-paginator *ngIf="totalItemsCount > perPage" [length]="totalItemsCount" [pageSize]="perPage"></mat-paginator>
  </nb-card-body>
</nb-card>
<ng-template #loader>
  <admin-loader></admin-loader>
</ng-template>
<admin-add-btn *ngIf="withAddBtn"></admin-add-btn>

import {ElementRef, Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';


@Injectable({providedIn: 'root'})
export class PageService {
  private _showGlobalLoader: boolean;
  private _hideGlobalLoader: boolean; // т.к. showGlobalLoader = false мы выставляем с задержкой - то нам нужна ещё одна переменная, чтобы понимать, стоит ли реально скрывать, или опять нужно показывать
  public unauthorizedResponseFromAPI = new ReplaySubject();

  constructor() {
  }

  set showGlobalLoader(show: boolean) {
    if (show) {
      this._hideGlobalLoader = false;
      this._showGlobalLoader = true;
    } else {
      this._hideGlobalLoader = true;
      setTimeout(() => {
        if (this._hideGlobalLoader) {
          this._showGlobalLoader = false;
        }
      }, 200);
    }
  }

  get globalLoaderWillBeHiddenSoon(): boolean {
    return this._hideGlobalLoader;
  }

  get showGlobalLoader(): boolean {
    return this._showGlobalLoader;
  }

  static getPositionOnViewport(el: ElementRef | Element, position: 'all'): ClientRect | DOMRect;
  static getPositionOnViewport(el: ElementRef | Element, position?: 'top' | 'bottom' | 'right' | 'left'): number;
  static getPositionOnViewport(el: ElementRef | Element, position: 'top' | 'bottom' | 'right' | 'left' | 'all' = 'top'): ClientRect | DOMRect | number {
    if (!el) {
      return 0;
    }
    el = (el['nativeElement'] || el) as Element;
    return el.getBoundingClientRect ? (position === 'all' ? el.getBoundingClientRect() : el.getBoundingClientRect()[position]) : 0;
  }
}

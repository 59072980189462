import { Injectable } from '@angular/core';
import { GraphQlService } from './graph-ql.service';
import gql from 'graphql-tag';
import { map } from 'rxjs/operators';
import { StaticPageQuery, StaticPageQueryVariables } from '@ew-math/graphql-generated-classes';

@Injectable()
export class CommonStaticPageService extends GraphQlService {

  staticPage(url: string) {
    return this.query<StaticPageQuery, StaticPageQueryVariables>({
      query: gql`
        query StaticPage($url: String!) {
          staticPage(url: $url) {
            body
            title
            id
            url
            updatedAt
          }
        }
      `,
      variables: {url}
    }).pipe(map(response => response.staticPage));
  }
}

import { AfterViewInit, Component, ContentChild, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '../../../pages/_services/page.service';
import { IApiService } from '../../../pages/_services/IApiService';
import { MatInput } from '@angular/material/input';
import { IDocument } from '@ew-math/api-interfaces';
import { CommonDialogComponent } from '../dialog/common-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'admin-edit-item',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.scss']
})
export class EditItemComponent implements OnInit, AfterViewInit {
  @ContentChild(MatInput, {static: false}) input: MatInput;
  @Input() itemService: IApiService<IDocument> & EditableItem<IDocument>;
  @Input() allowAddNew = true;
  @Input() allowEmptyItem = false;
  @Output() save = new EventEmitter<{ id: number, value: any }>();
  @Output() formGroupInit = new EventEmitter();
  @Output() itemReceive = new EventEmitter();
  formGroup: FormGroup;
  item: IDocument;
  itemId: string


  constructor(private route: ActivatedRoute,
              public pageService: PageService,
              private dialog: MatDialog,
              private router: Router) {
  }

  ngOnInit(): void {
    if (!this.itemService) {
      throw 'Input itemService is required';
    }
    this.route.paramMap.subscribe(params => {
      this.itemId = params.get('id');
      if (this.itemId !== 'add') {
        this.itemService.getById(this.itemId).subscribe(item => {
          if ((!item || !item.id) && !this.allowEmptyItem) {
            this.goToItemsList();
            return;
          }
          if (item) {
            this.item = item;
            this.initFormGroup();
          } else {
            this.item = this.itemService.initItem();
            this.initFormGroup();
          }
          this.itemReceive.emit(item);
        }, err => {
          this.goToItemsList();
        });
      } else if (this.allowAddNew) {
        this.item = this.itemService.initItem();
        this.initFormGroup();
      } else {
        this.goToItemsList();
      }
    })
  }

  submit() {
    if (this.formGroup.valid) {
      this.pageService.showGlobalLoader = true;
      let observable;

      this.itemService.update(this.formGroup.value, this.itemId).subscribe(response => {
         this.pageService.showGlobalLoader = false;
        this.save.emit({id: this.item.id || response, value: this.formGroup.value});
        this.formGroup.markAsPristine();
        this.goToItemsList();
       }, err => {
        let errors;
        if (err && typeof err === 'object') {
          if ('fieldError' in err) {
            errors = err.fieldError.error;
          }
        } else {
          errors = 'Some error occured. Please try again';
        }
        if (errors) {
          return this.dialog.open(CommonDialogComponent, {
            data: {
              msg: errors
            }
          });
        }
      });
    }
  }

  goToItemsList() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  canDeactivate() {
    return !this.formGroup || this.formGroup.pristine;
  }

  initFormGroup() {
    this.formGroup = this.itemService.initFormGroup(this.item);
    this.formGroupInit.emit();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.input) {
        this.input.focus();
      }
    }, 100);
  }

}


export interface EditableItem<T> {
  initItem?(): T;

  initFormGroup(item: T): FormGroup;
}

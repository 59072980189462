import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NbAuthOAuth2JWTToken, NbAuthService } from '@nebular/auth';
import { IJwtPayload } from '@ew-math/api-interfaces';
import { avatars } from '@ew-math/images';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: IJwtPayload;


  userMenu: NbMenuItem[] = [{title: 'Log out', link: '/auth/logout'}];
  userPic: string;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,
              private authService: NbAuthService,
              private breakpointService: NbMediaBreakpointsService) {
  }

  ngOnInit() {
    this.authService.getToken().pipe(takeUntil(this.destroy$)).subscribe((token: NbAuthOAuth2JWTToken) => {
      this.user = token.getAccessTokenPayload();
      if (this.user.avatarId) {
        this.userPic = '/assets/images/avatars/' + avatars.find(avatar => avatar.id === this.user.avatarId).x1;
      } else if (this.user.gravatarHash) {
        this.userPic = 'https://www.gravatar.com/avatar/' + this.user.gravatarHash;
      }
    });
    const {xl} = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}

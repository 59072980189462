export interface IAvatar {
  id: number;
  x1: string;
  x2: string;
  x3: string;
}

export const avatars: IAvatar[] = [...Array(10).keys()].map(id => {
  id++;
  return {
    id,
    x1: `ava-${id}.png`,
    x2: `ava-${id}@2x.png`,
    x3: `ava-${id}@3x.png`,
  }
});

import { Apollo } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { throwError } from 'rxjs';
import { ApolloError, QueryOptions } from 'apollo-client';
import { catchError, map } from 'rxjs/operators';
import { FormFieldError } from '../../../../apps/api/src/app/core/errors';
import { R } from 'apollo-angular/types';
import { GlobalErrorsService } from './global-errors.service';

export abstract class GraphQlService extends Apollo {

  mutate<MutationType, MutationVariables = R>(options: {mutation: DocumentNode, variables?: MutationVariables}) {
    return super.mutate<MutationType, MutationVariables>(options).pipe(
      map(response => response.data),
      catchError(err => this.catchError(err, 'mutation'))
    );
  }

  query<QueryType, QueryOptionsType = R>(options: QueryOptions<QueryOptionsType>) {
    // do not cache queries on server
    return super.query<QueryType, QueryOptionsType>({...options, fetchPolicy: typeof document === 'undefined' ? 'network-only' : options.fetchPolicy || undefined}).pipe(
      map(response => response.data),
      catchError(err => this.catchError(err, 'query'))
    );
  }


  catchError(err: ApolloError, requestType: 'query' | 'mutation') {
    if ('graphQLErrors' in err && err.graphQLErrors.length > 0) {
      let fieldError: FormFieldError;
      err.graphQLErrors.forEach(gqlErr => {
        if (gqlErr.extensions.code === 'BAD_USER_INPUT') {
          fieldError = gqlErr.extensions.exception;
        } else if (gqlErr.extensions.code === 'UNAUTHENTICATED') {
          GlobalErrorsService.getInstance().unautheticatedError.next(gqlErr);
        }
      });
      if (fieldError) {
        return throwError({fieldError});
      }
    } else if ('networkError' in err) {
      GlobalErrorsService.getInstance().networkError.next({error: err['networkError'], isCritical: requestType === 'mutation'});
    }
    return throwError(err);
  }


}

import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { GraphQLError } from 'graphql';

@Injectable()
export class GlobalErrorsService {
  private static _instance: GlobalErrorsService;
  unautheticatedError = new Subject<GraphQLError>();
  networkError = new Subject<{error: Error, isCritical: boolean}>();

  constructor() {
    return GlobalErrorsService._instance || (GlobalErrorsService._instance = this);
  }

  static getInstance() {
    return Injector.create({
      providers: [{
        provide: GlobalErrorsService,
        deps: []
      }]
    }).get(GlobalErrorsService);
  }
}

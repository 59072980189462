import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


export enum dialogButtons {cancel, yes, no, ok}

@Component({
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss']
})
export class CommonDialogComponent implements OnInit {
  buttons = dialogButtons;
  text: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { msg?: string, viewButtons?: dialogButtons[] },
              public dialogRef: MatDialogRef<CommonDialogComponent>) {
    if (!data.viewButtons) {
      data.viewButtons = [dialogButtons.ok];
    }
  }

  ngOnInit(): void {// это чтобы отобразить close icon
    if (!!this.data.msg) {
      this.text = this.data.msg;
    }
  }
}

<div *ngIf="formGroup; else loader">
  <form [formGroup]="formGroup" (ngSubmit)="submit()" adminBeforeUnloadMessage [preventUnload]="!canDeactivate()">
    <ng-content></ng-content>
    <div class="save-btn-holder">
      <button mat-raised-button color="primary" [disabled]="formGroup.pristine || !formGroup.valid">SAVE</button>
    </div>
  </form>
</div>

<ng-template #loader>
  <admin-loader></admin-loader>
</ng-template>

import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { IApiService } from '../../../pages/_services/IApiService';
import { PageService } from '../../../pages/_services/page.service';
import { IListableItems } from './i-listable-items';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { IOrderableItems } from './i-orderable-items';
import { NbSearchService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { IDocument } from '@ew-math/api-interfaces';

@Component({
  selector: 'admin-items-list',
  templateUrl: './items-list.component.html',
  styleUrls: [
    './items-list.component.scss'
  ]
})
export class ItemsListComponent<T extends IDocument> implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatTable, {static: false}) table: MatTable<any>;
  reorderableList = false;
  dataSource: MatTableDataSource<any>;
  totalItemsCount: number;
  initialLoading = true;
  waitingForItems = true;
  columnsToDisplay: string[];
  perPage = 25;
  itemService: IListableItems<T> & IApiService<T>;
  title: string;
  items: T[];
  withAddBtn: boolean;
  withSearch: boolean;
  searchBy: string;
  onSearch: Subscription;
  searchPhrase: string;
  routeSubscription: Subscription;
  paginatorSubscription: Subscription;

  constructor(private pageService: PageService,
              private injector: Injector,
              private route: ActivatedRoute,
              private router: Router,
              private nbSearchService: NbSearchService) {
  }

  ngOnInit(): void {
    this.withAddBtn = !!this.route.snapshot.data.withoutAddBtn === false;
    this.withSearch = !!this.route.snapshot.data.withSearch;
    this.searchBy = this.route.snapshot.data.searchBy || 'Search';
    this.reorderableList = this.route.snapshot.data.reorderableList;
    if (!this.route.snapshot.data.itemService) {
      throw 'itemService wasn\'t provided';
    }
    this.itemService = this.injector.get(this.route.snapshot.data.itemService);
    if (!this.itemService) {
      throw 'Incorrect itemService was provided';
    }
    this.title = this.itemService.itemsListPageTitle;
    this.columnsToDisplay = this.itemService.columnsToDisplay;
    if (this.withSearch) {
      this.onSearch = this.nbSearchService.onSearchSubmit().subscribe(search => {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
          queryParams: {search: search.term}
        });
      });
    }
    this.routeSubscription = this.route.queryParamMap.subscribe(params => {
      this.searchPhrase = params.get('search');
      this.initialLoading = true;
      this.getItems();
    });
  }

  getItems() {
    this.itemService.pagedQuery(this.paginator ? this.paginator.pageIndex : 0, this.perPage, this.initialLoading, this.searchPhrase).subscribe(data => {
      this.pageService.showGlobalLoader = false;
      this.waitingForItems = false;
      if (data && data.elements) {
        this.items = data.elements;
        this.dataSource = new MatTableDataSource(this.items);
        setTimeout(() => {
          this.table.renderRows();
        })
        if (this.initialLoading) {
          this.totalItemsCount = data.total;
          this.initialLoading = false;
          setTimeout(() => {
            if (this.paginator) {
              if (this.paginatorSubscription) {
                this.paginatorSubscription.unsubscribe();
              }
              this.paginatorSubscription = this.paginator.page.subscribe(() => {
                this.pageService.showGlobalLoader = true;
                this.getItems();
              });
            }
          });
        }
      }
    });
  }

  onListDrop(event: CdkDragDrop<MatTableDataSource<T>>) {
    const movedItem = this.items.find(item => item === event.item.data);
    if (movedItem) {
      const prevIndex = this.items.indexOf(movedItem);
      if (prevIndex !== event.currentIndex) {
        if ('saveOrder' in this.itemService) {
          (this.itemService as unknown as IOrderableItems<T>).saveOrder(movedItem.id, prevIndex - event.currentIndex).subscribe();
        }
        moveItemInArray(this.items, prevIndex, event.currentIndex);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.onSearch) {
      this.onSearch.unsubscribe();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.paginatorSubscription) {
      this.paginatorSubscription.unsubscribe();
    }
  }
}

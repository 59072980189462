import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { CommonAuthService } from '@ew-math/services-lib';
import { catchError, mergeMap } from 'rxjs/operators';
import { UserType } from '@ew-math/graphql-generated-classes';

/**
 * ngx-admin doesn't support GraphQl at the moment. So, we'll use this Interceptor to send auth requests to our GraphQL API
 */
@Injectable()
export class HTTPToGraphQLInterceptor implements HttpInterceptor {

  constructor(private commonAuthService: CommonAuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let graphQlMethod;
    switch (req.url) {
      case '/api/auth/login':
        graphQlMethod = this.commonAuthService.login({
          email: req.body.email,
          password: req.body.password,
          loginFromAdmin: true,
          rememberMe: req.body.rememberMe
        });
        break;
      case '/api/auth/refresh-token':
        graphQlMethod = this.commonAuthService.refreshToken(req.body.token);
        break;
      case '/api/auth/logout':
        graphQlMethod = this.commonAuthService.logout();
        break;
      case '/api/auth/register':
        graphQlMethod = this.commonAuthService.signup({
          email: req.body.email,
          name: req.body.fullName,
          password: req.body.password,
          type: UserType.Regular
        });
        break;
      case '/api/auth/request-pass':
        graphQlMethod = this.commonAuthService.requestPass(req.body.email);
        break;
      case '/api/auth/reset-pass':
        graphQlMethod = this.commonAuthService.restorePass(req.body.password, req.body.code);
        break;
    }
    if (graphQlMethod) {
      return graphQlMethod.pipe(mergeMap(response => {
          return of(new HttpResponse({body: response, status: 200}));
        }), catchError(response => {
          if (response && 'fieldError' in response) {
            return throwError(new HttpErrorResponse({
              error: {data: {errors: [response.fieldError.error]}},
            }));
          } else {
            return throwError(new HttpErrorResponse({
              error: {data: {errors: ['Something went wrong']}},
            }));
          }
        })
      );
    }
    return next.handle(req);
  }
}

import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import {
  JwtKeys,
  LoginInput,
  LoginMutation,
  LoginMutationVariables,
  LogoutMutation,
  RefreshTokenQuery,
  RefreshTokenQueryVariables,
  RequestPassMutation,
  RequestPassMutationVariables, RestorePassMutation, RestorePassMutationVariables,
  SignUpInput,
  SignUpMutation,
  SignUpMutationVariables,
} from '@ew-math/graphql-generated-classes';
import { map } from 'rxjs/operators';
import { GraphQlService } from './graph-ql.service';

export const userFragment = gql`
  fragment UserFragment on User {
    id
    email
    name
    fullName
    second_name
    avatarId
    type
    paid
    paid_student_accounts
    passedChallenges
    class {
      id
      name
      link
    }
  }
`;

@Injectable()
export class CommonAuthService extends GraphQlService {

  authFragment = gql`
    fragment AuthFragment on AuthPayload {
      token {
        access_token
        refresh_token
      }
      user {
        passedChallenges
        ...UserFragment
      }
    }

    ${userFragment}
  `;

  signup(user: SignUpInput) {
    return this.mutate<SignUpMutation, SignUpMutationVariables>({
      mutation: gql`
        mutation SignUp($user: SignUpInput!){
          signup(signUpInput: $user) {
            ...AuthFragment
          }
        }

        ${this.authFragment}
      `,
      variables: {user}
    }).pipe(map(response => response.signup));
  }

  requestPass(email: string) {
    return this.mutate<RequestPassMutation, RequestPassMutationVariables>({
      mutation: gql`
        mutation RequestPass($email: String!){
          requestPass(email: $email)
        }
      `,
      variables: {email}
    }).pipe(map(response => response.requestPass));
  }

  restorePass(password: string, code: string) {
    return this.mutate<RestorePassMutation, RestorePassMutationVariables>({
      mutation: gql`
        mutation RestorePass($password: String!, $code: String!){
          restorePass(password: $password, code: $code) {
            app_type
          }
        }
      `,
      variables: {password, code}
    }).pipe(map(response => response.restorePass));
  }

  logout() {
    return this.mutate<LogoutMutation>({
      mutation: gql`
        mutation Logout {
          logout
        }
      `,
    }).pipe(map(response => response.logout));
  }

  refreshToken(jwt: JwtKeys) {
    return this.query<RefreshTokenQuery, RefreshTokenQueryVariables>({
      query: gql`
        query RefreshToken($refreshToken: String!) {
          refreshToken(refreshToken: $refreshToken) {
            ...AuthFragment
          }
        }

        ${this.authFragment}
      `,
      variables: {refreshToken: jwt.refresh_token}
    }).pipe(map(response => {
      return response.refreshToken;
    }));
  }

  login(user: LoginInput) {
    return this.mutate<LoginMutation, LoginMutationVariables>({
      mutation: gql`
        mutation Login($user: LoginInput!){
          login(loginInput: $user) {
            ...AuthFragment
          }
        }

        ${this.authFragment}
      `,
      variables: {user}
    }).pipe(map(response => response.login));
  }

}

import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'admin-loader',
  templateUrl: './loader.component.html',
  styleUrls: [
    './loader.component.scss'
  ]
})
export class LoaderComponent {
  @Input() blockUi: boolean;
  @HostBinding('class.blockUi')
  get blockedUi() {
    return this.blockUi;
  }
}

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum AppType {
  Web = 'WEB',
  Ios = 'IOS',
  Android = 'ANDROID'
}

export type AuthPayload = {
  __typename?: 'AuthPayload';
  user: User;
  token: JwtKeys;
};

export type AvgStatistics = {
  __typename?: 'AvgStatistics';
  gameType: GameType;
  subGameType: Scalars['String'];
  lastGameDate: Scalars['String'];
  time: Scalars['Float'];
  tries: Scalars['Float'];
  errors: Scalars['Float'];
  games: Scalars['Int'];
};

export type Class = {
  __typename?: 'Class';
  id: Scalars['ID'];
  name: Scalars['String'];
  link: Scalars['String'];
  students?: Maybe<Array<User>>;
  studentsCount?: Maybe<Scalars['Int']>;
  learningTen?: Maybe<Scalars['Int']>;
  disabledGames?: Maybe<Array<Scalars['String']>>;
};

export type ClassInput = {
  name: Scalars['String'];
};

export enum GameType {
  Compare = 'COMPARE',
  Recognize = 'RECOGNIZE',
  Recognize1120 = 'RECOGNIZE1120',
  Compose = 'COMPOSE',
  ChallengeAddTo_10 = 'CHALLENGE_ADD_TO_10',
  ChallengeSubTo_10 = 'CHALLENGE_SUB_TO_10',
  ChallengeNums_11_20 = 'CHALLENGE_NUMS_11_20',
  ChallengeAddTo_20 = 'CHALLENGE_ADD_TO_20',
  ChallengeSubTo_20 = 'CHALLENGE_SUB_TO_20'
}

export type JwtKeys = {
  __typename?: 'JwtKeys';
  access_token: Scalars['String'];
  refresh_token?: Maybe<Scalars['String']>;
};

export type LoginDetailsForStudent = {
  __typename?: 'LoginDetailsForStudent';
  link: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe?: Maybe<Scalars['Boolean']>;
  loginFromAdmin?: Maybe<Scalars['Boolean']>;
};

export enum LoginTokenFor {
  User = 'USER',
  ClassStudents = 'CLASS_STUDENTS',
  ClassStudentsWithoutLink = 'CLASS_STUDENTS_WITHOUT_LINK'
}

export type Mutation = {
  __typename?: 'Mutation';
  signup: AuthPayload;
  login: AuthPayload;
  loginByToken: AuthPayload;
  requestPass: Scalars['Boolean'];
  restorePass: User;
  logout: Scalars['Boolean'];
  generateLoginToken: LoginDetailsForStudent;
  deleteUser?: Maybe<Scalars['Boolean']>;
  updateUser: User;
  incStudentPaidAccounts: User;
  createClass: Class;
  updateClass: Class;
  addStudent: User;
  removeStudent: Scalars['Boolean'];
  loginAsStudent: AuthPayload;
  deleteStudents?: Maybe<Scalars['Boolean']>;
  saveStatisitcs: Scalars['ID'];
  savePayment: User;
  saveSettings: Settings;
  updateStaticPage: StaticPage;
  challengePassed: User;
};


export type MutationSignupArgs = {
  signUpInput: SignUpInput;
};


export type MutationLoginArgs = {
  loginInput: LoginInput;
};


export type MutationLoginByTokenArgs = {
  token: Scalars['String'];
};


export type MutationRequestPassArgs = {
  email: Scalars['String'];
};


export type MutationRestorePassArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
};


export type MutationGenerateLoginTokenArgs = {
  userOrClassId: Scalars['ID'];
  tokenFor: LoginTokenFor;
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
  id?: Maybe<Scalars['ID']>;
};


export type MutationIncStudentPaidAccountsArgs = {
  accounts: Scalars['Int'];
  active_until?: Maybe<Scalars['String']>;
  teacherId: Scalars['ID'];
};


export type MutationCreateClassArgs = {
  class: ClassInput;
};


export type MutationUpdateClassArgs = {
  data: UpdateClassInput;
};


export type MutationAddStudentArgs = {
  student: StudentInput;
};


export type MutationRemoveStudentArgs = {
  studentId?: Maybe<Scalars['ID']>;
};


export type MutationLoginAsStudentArgs = {
  studentId: Scalars['ID'];
  classId: Scalars['ID'];
};


export type MutationDeleteStudentsArgs = {
  teacherId: Scalars['ID'];
};


export type MutationSaveStatisitcsArgs = {
  data: StatisticsInput;
};


export type MutationSavePaymentArgs = {
  orderOrTransactionId: Scalars['String'];
};


export type MutationSaveSettingsArgs = {
  settings: SettingsInput;
};


export type MutationUpdateStaticPageArgs = {
  staticPage: StaticPageInput;
  url: Scalars['String'];
};


export type MutationChallengePassedArgs = {
  challenge: Scalars['String'];
};

export type PagedQueryInput = {
  pageIndex: Scalars['Int'];
  perPage: Scalars['Int'];
  getTotal: Scalars['Boolean'];
  search?: Maybe<Scalars['String']>;
};

export type PagedUsers = {
  __typename?: 'PagedUsers';
  total?: Maybe<Scalars['Int']>;
  elements: Array<Maybe<User>>;
};

export enum PaymentFrom {
  Apple = 'APPLE',
  Google = 'GOOGLE',
  Paypal = 'PAYPAL',
  Admin = 'ADMIN',
  Shopify = 'SHOPIFY'
}

export type Query = {
  __typename?: 'Query';
  user: User;
  currentUser: User;
  pagedUsers: PagedUsers;
  refreshToken: AuthPayload;
  teacherClasses: Array<Class>;
  teacherStudent?: Maybe<User>;
  getClass: Class;
  getStudentClass: StudentClass;
  checkTeachersPIN: Scalars['Boolean'];
  allStudentsIds: Array<Maybe<Scalars['ID']>>;
  canAddMoreStudents: Scalars['Boolean'];
  settings?: Maybe<Settings>;
  settingsForEdit: SettingsForEdit;
  statistics?: Maybe<Array<AvgStatistics>>;
  lastStatistics?: Maybe<Array<Maybe<Statistics>>>;
  staticPage: StaticPage;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryPagedUsersArgs = {
  pagedQueryInput: PagedQueryInput;
};


export type QueryRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};


export type QueryTeacherStudentArgs = {
  id: Scalars['ID'];
};


export type QueryGetClassArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryCheckTeachersPinArgs = {
  pin: Scalars['String'];
};


export type QueryStatisticsArgs = {
  uid?: Maybe<Scalars['ID']>;
};


export type QueryLastStatisticsArgs = {
  gameType: GameType;
  subGameType: Scalars['String'];
  take?: Maybe<Scalars['Int']>;
};


export type QueryStaticPageArgs = {
  url: Scalars['String'];
};

export type Settings = {
  __typename?: 'Settings';
  premium_cost_on_paypal: Scalars['Float'];
  product_name_on_paypal: Scalars['String'];
  contact_email: Scalars['String'];
  contact_phone: Scalars['String'];
  contact_address: Scalars['String'];
  paypalClientId: Scalars['String'];
  shopify_product_id?: Maybe<Scalars['String']>;
};

export type SettingsForEdit = {
  __typename?: 'SettingsForEdit';
  premium_cost_on_paypal?: Maybe<Scalars['Float']>;
  product_name_on_paypal?: Maybe<Scalars['String']>;
  contact_email?: Maybe<Scalars['String']>;
  contact_phone: Scalars['String'];
  contact_address: Scalars['String'];
  shopify_product_id?: Maybe<Scalars['String']>;
};

export type SettingsInput = {
  premium_cost_on_paypal?: Maybe<Scalars['Float']>;
  product_name_on_paypal?: Maybe<Scalars['String']>;
  contact_email?: Maybe<Scalars['String']>;
  contact_phone: Scalars['String'];
  contact_address: Scalars['String'];
  shopify_product_id?: Maybe<Scalars['String']>;
};

export type SignUpInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  name: Scalars['String'];
  type: UserType;
};

export type StaticPage = {
  __typename?: 'StaticPage';
  id: Scalars['ID'];
  url: Scalars['String'];
  title: Scalars['String'];
  body: Scalars['String'];
  body_in_app?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type StaticPageInput = {
  title: Scalars['String'];
  body: Scalars['String'];
  body_in_app?: Maybe<Scalars['String']>;
};

export type Statistics = {
  __typename?: 'Statistics';
  id: Scalars['ID'];
  student: Scalars['ID'];
  gameType: GameType;
  subGameType: Scalars['String'];
  date: Scalars['String'];
  tries: Scalars['Int'];
  errors: Scalars['Int'];
};

export type StatisticsInput = {
  gameType: GameType;
  subGameType: Scalars['String'];
  time: Scalars['Int'];
  tries: Scalars['Int'];
  errors: Scalars['Int'];
};

export type StudentClass = {
  __typename?: 'StudentClass';
  id: Scalars['ID'];
  name: Scalars['String'];
  link: Scalars['String'];
  learningTen?: Maybe<Scalars['Int']>;
  disabledGames?: Maybe<Array<Scalars['String']>>;
};

export type StudentInput = {
  name: Scalars['String'];
  second_name: Scalars['String'];
  contact_email: Scalars['String'];
  class: Scalars['ID'];
};

export enum SubGameType {
  ComposeFrameVsFrame = 'COMPOSE_FRAME_VS_FRAME',
  ComposeFrameVsNum = 'COMPOSE_FRAME_VS_NUM',
  ComposeNumVsNum = 'COMPOSE_NUM_VS_NUM',
  ComposeMemory = 'COMPOSE_MEMORY',
  CompareFrameVsFrame = 'COMPARE_FRAME_VS_FRAME',
  CompareFrameVsNum = 'COMPARE_FRAME_VS_NUM',
  CompareNumVsNum = 'COMPARE_NUM_VS_NUM',
  RecognizeListen = 'RECOGNIZE_LISTEN',
  RecognizeSay = 'RECOGNIZE_SAY',
  RecognizeMemory = 'RECOGNIZE_MEMORY',
  RecognizeSeeFrame = 'RECOGNIZE_SEE_FRAME',
  RecognizeSeeNum = 'RECOGNIZE_SEE_NUM'
}

export type UpdateClassInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  learningTen?: Maybe<Scalars['Int']>;
  disabledGames?: Maybe<Array<Scalars['String']>>;
};

export type UpdateUserInput = {
  teachersPIN?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  second_name?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['ID']>;
  avatarId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
  password?: Maybe<Scalars['String']>;
  contact_email?: Maybe<Scalars['String']>;
  paidUntil?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email: Scalars['String'];
  name: Scalars['String'];
  avatarId?: Maybe<Scalars['Int']>;
  type: UserType;
  teachersPIN?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  class?: Maybe<Class>;
  paid?: Maybe<Scalars['Boolean']>;
  paid_student_accounts?: Maybe<Scalars['Int']>;
  contact_email?: Maybe<Scalars['String']>;
  last_login_token_exists?: Maybe<Scalars['Boolean']>;
  app_type?: Maybe<AppType>;
  fullName?: Maybe<Scalars['String']>;
  second_name?: Maybe<Scalars['String']>;
  passedChallenges?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum UserType {
  Regular = 'REGULAR',
  Admin = 'ADMIN',
  Teacher = 'TEACHER',
  Student = 'STUDENT'
}

export type SettingsForEditQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsForEditQuery = (
  { __typename?: 'Query' }
  & { settingsForEdit: (
    { __typename?: 'SettingsForEdit' }
    & Pick<SettingsForEdit, 'contact_email' | 'contact_address' | 'contact_phone' | 'premium_cost_on_paypal' | 'product_name_on_paypal' | 'shopify_product_id'>
  ) }
);

export type SaveSettingsMutationVariables = Exact<{
  settings: SettingsInput;
}>;


export type SaveSettingsMutation = (
  { __typename?: 'Mutation' }
  & { saveSettings: (
    { __typename?: 'Settings' }
    & Pick<Settings, 'contact_email' | 'contact_address' | 'contact_phone' | 'premium_cost_on_paypal' | 'product_name_on_paypal' | 'shopify_product_id'>
  ) }
);

export type StaticPageForAdminQueryVariables = Exact<{
  url: Scalars['String'];
}>;


export type StaticPageForAdminQuery = (
  { __typename?: 'Query' }
  & { staticPage: (
    { __typename?: 'StaticPage' }
    & Pick<StaticPage, 'body' | 'body_in_app' | 'title' | 'id'>
  ) }
);

export type UpdateStaticPageMutationVariables = Exact<{
  staticPage: StaticPageInput;
  url: Scalars['String'];
}>;


export type UpdateStaticPageMutation = (
  { __typename?: 'Mutation' }
  & { updateStaticPage: (
    { __typename?: 'StaticPage' }
    & Pick<StaticPage, 'id' | 'body' | 'body_in_app' | 'title'>
  ) }
);

export type PagedUsersQueryVariables = Exact<{
  pagedQueryInput: PagedQueryInput;
}>;


export type PagedUsersQuery = (
  { __typename?: 'Query' }
  & { pagedUsers: (
    { __typename?: 'PagedUsers' }
    & Pick<PagedUsers, 'total'>
    & { elements: Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email' | 'id' | 'name' | 'type' | 'app_type'>
    )>> }
  ) }
);

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'email' | 'id' | 'name' | 'type' | 'paid_student_accounts' | 'fullName' | 'second_name' | 'app_type' | 'paid'>
  ) }
);

export type IncStudentPaidAccountsMutationVariables = Exact<{
  accounts: Scalars['Int'];
  active_until: Scalars['String'];
  teacherId: Scalars['ID'];
}>;


export type IncStudentPaidAccountsMutation = (
  { __typename?: 'Mutation' }
  & { incStudentPaidAccounts: (
    { __typename?: 'User' }
    & Pick<User, 'paid_student_accounts'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type DeleteStudentsMutationVariables = Exact<{
  teacherId: Scalars['ID'];
}>;


export type DeleteStudentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStudents'>
);

export type LoginByTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type LoginByTokenMutation = (
  { __typename?: 'Mutation' }
  & { loginByToken: (
    { __typename?: 'AuthPayload' }
    & AuthFragmentFragment
  ) }
);

export type LoginAsStudentMutationVariables = Exact<{
  studentId: Scalars['ID'];
  classId: Scalars['ID'];
}>;


export type LoginAsStudentMutation = (
  { __typename?: 'Mutation' }
  & { loginAsStudent: (
    { __typename?: 'AuthPayload' }
    & { user: (
      { __typename?: 'User' }
      & UserFragmentFragment
    ), token: (
      { __typename?: 'JwtKeys' }
      & Pick<JwtKeys, 'access_token'>
    ) }
  ) }
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'User' }
    & UserFragmentFragment
  ) }
);

export type CheckTeachersPinQueryVariables = Exact<{
  pin: Scalars['String'];
}>;


export type CheckTeachersPinQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkTeachersPIN'>
);

export type GenerateLoginTokenMutationVariables = Exact<{
  userOrClassId: Scalars['ID'];
  tokenFor: LoginTokenFor;
}>;


export type GenerateLoginTokenMutation = (
  { __typename?: 'Mutation' }
  & { generateLoginToken: (
    { __typename?: 'LoginDetailsForStudent' }
    & Pick<LoginDetailsForStudent, 'email' | 'link' | 'password'>
  ) }
);

export type ClassFragmentFragment = (
  { __typename?: 'Class' }
  & Pick<Class, 'id' | 'name' | 'link' | 'disabledGames' | 'learningTen'>
);

export type CreateClassMutationVariables = Exact<{
  classData: ClassInput;
}>;


export type CreateClassMutation = (
  { __typename?: 'Mutation' }
  & { createClass: (
    { __typename?: 'Class' }
    & ClassFragmentFragment
  ) }
);

export type TeacherClassesQueryVariables = Exact<{ [key: string]: never; }>;


export type TeacherClassesQuery = (
  { __typename?: 'Query' }
  & { teacherClasses: Array<(
    { __typename?: 'Class' }
    & Pick<Class, 'id' | 'link' | 'name' | 'studentsCount'>
  )> }
);

export type TeacherClassesWithStudentsQueryVariables = Exact<{ [key: string]: never; }>;


export type TeacherClassesWithStudentsQuery = (
  { __typename?: 'Query' }
  & { teacherClasses: Array<(
    { __typename?: 'Class' }
    & Pick<Class, 'id' | 'name' | 'link'>
    & { students?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'fullName'>
    )>> }
  )> }
);

export type GetClassQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type GetClassQuery = (
  { __typename?: 'Query' }
  & { getClass: (
    { __typename?: 'Class' }
    & { students?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'fullName' | 'avatarId' | 'last_login_token_exists'>
    )>> }
    & ClassFragmentFragment
  ) }
);

export type GetStudentClassQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStudentClassQuery = (
  { __typename?: 'Query' }
  & { getStudentClass: (
    { __typename?: 'StudentClass' }
    & Pick<StudentClass, 'disabledGames' | 'id' | 'learningTen' | 'link' | 'name'>
  ) }
);

export type UpdateClassMutationVariables = Exact<{
  classData: UpdateClassInput;
}>;


export type UpdateClassMutation = (
  { __typename?: 'Mutation' }
  & { updateClass: (
    { __typename?: 'Class' }
    & ClassFragmentFragment
  ) }
);

export type SavePaymentMutationVariables = Exact<{
  orderOrTransactionId: Scalars['String'];
}>;


export type SavePaymentMutation = (
  { __typename?: 'Mutation' }
  & { savePayment: (
    { __typename?: 'User' }
    & Pick<User, 'paid'>
  ) }
);

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = (
  { __typename?: 'Query' }
  & { settings?: Maybe<(
    { __typename?: 'Settings' }
    & Pick<Settings, 'premium_cost_on_paypal' | 'contact_email' | 'contact_address' | 'contact_phone' | 'paypalClientId' | 'product_name_on_paypal'>
  )> }
);

export type SaveStatisitcsMutationVariables = Exact<{
  data: StatisticsInput;
}>;


export type SaveStatisitcsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveStatisitcs'>
);

export type LastStatisticsQueryVariables = Exact<{
  gameType: GameType;
  subGameType: Scalars['String'];
  take: Scalars['Int'];
}>;


export type LastStatisticsQuery = (
  { __typename?: 'Query' }
  & { lastStatistics?: Maybe<Array<Maybe<(
    { __typename?: 'Statistics' }
    & Pick<Statistics, 'errors' | 'tries'>
  )>>> }
);

export type StatisticsQueryVariables = Exact<{
  uid?: Maybe<Scalars['ID']>;
}>;


export type StatisticsQuery = (
  { __typename?: 'Query' }
  & { statistics?: Maybe<Array<(
    { __typename?: 'AvgStatistics' }
    & Pick<AvgStatistics, 'errors' | 'gameType' | 'games' | 'lastGameDate' | 'subGameType' | 'time' | 'tries'>
  )>> }
);

export type AddStudentMutationVariables = Exact<{
  student: StudentInput;
}>;


export type AddStudentMutation = (
  { __typename?: 'Mutation' }
  & { addStudent: (
    { __typename?: 'User' }
    & UserFragmentFragment
  ) }
);

export type TeacherStudentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeacherStudentQuery = (
  { __typename?: 'Query' }
  & { teacherStudent?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'contact_email' | 'last_login_token_exists'>
    & UserFragmentFragment
  )> }
);

export type AllStudentsIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllStudentsIdsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'allStudentsIds'>
);

export type RemoveStudentMutationVariables = Exact<{
  studentId: Scalars['ID'];
}>;


export type RemoveStudentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeStudent'>
);

export type CanAddMoreStudentsQueryVariables = Exact<{ [key: string]: never; }>;


export type CanAddMoreStudentsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'canAddMoreStudents'>
);

export type ChallengePassedMutationVariables = Exact<{
  challenge: Scalars['String'];
}>;


export type ChallengePassedMutation = (
  { __typename?: 'Mutation' }
  & { challengePassed: (
    { __typename?: 'User' }
    & Pick<User, 'passedChallenges'>
  ) }
);

export type UserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'name' | 'fullName' | 'second_name' | 'avatarId' | 'type' | 'paid' | 'paid_student_accounts' | 'passedChallenges'>
  & { class?: Maybe<(
    { __typename?: 'Class' }
    & Pick<Class, 'id' | 'name' | 'link'>
  )> }
);

export type AuthFragmentFragment = (
  { __typename?: 'AuthPayload' }
  & { token: (
    { __typename?: 'JwtKeys' }
    & Pick<JwtKeys, 'access_token' | 'refresh_token'>
  ), user: (
    { __typename?: 'User' }
    & Pick<User, 'passedChallenges'>
    & UserFragmentFragment
  ) }
);

export type SignUpMutationVariables = Exact<{
  user: SignUpInput;
}>;


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & { signup: (
    { __typename?: 'AuthPayload' }
    & AuthFragmentFragment
  ) }
);

export type RequestPassMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestPassMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestPass'>
);

export type RestorePassMutationVariables = Exact<{
  password: Scalars['String'];
  code: Scalars['String'];
}>;


export type RestorePassMutation = (
  { __typename?: 'Mutation' }
  & { restorePass: (
    { __typename?: 'User' }
    & Pick<User, 'app_type'>
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type RefreshTokenQueryVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshTokenQuery = (
  { __typename?: 'Query' }
  & { refreshToken: (
    { __typename?: 'AuthPayload' }
    & AuthFragmentFragment
  ) }
);

export type LoginMutationVariables = Exact<{
  user: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'AuthPayload' }
    & AuthFragmentFragment
  ) }
);

export type StaticPageQueryVariables = Exact<{
  url: Scalars['String'];
}>;


export type StaticPageQuery = (
  { __typename?: 'Query' }
  & { staticPage: (
    { __typename?: 'StaticPage' }
    & Pick<StaticPage, 'body' | 'title' | 'id' | 'url' | 'updatedAt'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  data: UpdateUserInput;
  id?: Maybe<Scalars['ID']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & UserFragmentFragment
  ) }
);

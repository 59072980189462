import {Component, Input} from '@angular/core';

@Component({
  selector: 'admin-add-btn',
  templateUrl: './add-btn.component.html',
  styleUrls: [
    './add-btn.component.scss'
  ]
})
export class AddBtnComponent {
  @Input() link = './add';
}

import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[adminBeforeUnloadMessage]'
})
export class BeforeUnloadMessageDirective {
  @Input('preventUnload') preventUnload = true;

  constructor() { }

  @HostListener('window:beforeunload', ['$event'])
  beforeuload($event) {
    return this.preventUnload ? $event.returnValue = 'Your data will be lost!' : true;
  }
}
